export function ScrollTo(Id) {
    let section = document.getElementById(Id);

    let offset = section.getBoundingClientRect().top + window.pageYOffset - 100;

    window.scrollTo({
        top: offset,
        behavior: "smooth",
    });
}
