import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./index.css";
import App from "./App";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    return false;
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
    </BrowserRouter>
);
