import * as THREE from "three";
import { TrackballControls } from "three/addons/controls/TrackballControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

const avatarPath = require("../models/Avatar.gltf");
const avatarGlassPath = require("../models/AvatarGlass.gltf");

function Models() {
    let avatar;
    let avatarGlass;
    let controls;
    let touch = true;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    camera.position.setZ(5);

    const ambientLight = new THREE.AmbientLight(0xffffff, 3);
    scene.add(ambientLight);

    const avatarLoader = new GLTFLoader();
    avatarLoader.load(avatarPath, (gltf) => {
        const gltfScene = gltf.scene;
        avatar = gltfScene;

        const cameraDirection = new THREE.Vector3();
        camera.getWorldDirection(cameraDirection);

        avatar.lookAt(avatar.position.clone().add(cameraDirection));

        scene.add(gltfScene);
    });

    const avatarGlassLoader = new GLTFLoader();
    avatarGlassLoader.load(avatarGlassPath, (gltf) => {
        const gltfScene = gltf.scene;
        avatarGlass = gltfScene;
        gltfScene.rotation.y = 2;

        const newMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: 1,
        });

        gltfScene.traverse((child) => {
            if (child.isMesh) {
                child.material = newMaterial;
            }
        });

        scene.add(gltfScene);
    });

    const renderer = new THREE.WebGLRenderer({
        canvas: document.querySelector("#bg"),
        alpha: true,
        antialias: true,
    });
    renderer.setPixelRatio(window.devicePixelRatio * 1.5);
    renderer.setSize(
        document.getElementById("bg").clientWidth,
        document.getElementById("bg").clientHeight
    );

    controls = new TrackballControls(camera, renderer.domElement);
    controls.noPan = true;
    controls.noZoom = true;

    updateRotateSpeed();

    controls.staticMoving = false;
    controls.dynamicDampingFactor = 0.02;

    let rot = 0.001;

    let randX = (Math.random() >= 0.5 ? 1 : -1) * rot;
    let randY = (Math.random() >= 0.5 ? 1 : -1) * rot;
    let randZ = (Math.random() >= 0.5 ? 1 : -1) * rot;

    function animate() {
        requestAnimationFrame(animate);

        if (avatar) {
            avatar.rotation.x += randX;
            avatar.rotation.y += randY;
            avatar.rotation.z += randZ;
        }

        if (avatarGlass && avatar) {
            avatarGlass.rotation.copy(avatar.rotation);
        }

        controls.update();
        renderer.render(scene, camera);
    }

    document.getElementById("bg").addEventListener("click", () => {
        touch = false;
        updateRotateSpeed();
    });

    document.getElementById("bg").addEventListener("touch", () => {
        touch = true;
        updateRotateSpeed();
    });

    function updateRotateSpeed() {
        const bgWidth = document.getElementById("bg").clientWidth;
        let rotateSpeed;

        if (touch) {
            if (bgWidth < 400) {
                rotateSpeed = 4;
            } else if (bgWidth < 800) {
                rotateSpeed = 3;
            } else {
                rotateSpeed = 2;
            }
        } else {
            if (bgWidth < 400) {
                rotateSpeed = 10;
            } else if (bgWidth < 800) {
                rotateSpeed = 5;
            } else {
                rotateSpeed = 2.5;
            }
        }

        console.log(controls);
        controls.rotateSpeed = rotateSpeed;
        controls.update();
    }

    animate();
}

export default Models;
