import React, { useEffect, useState } from "react";
import "./Footer.css";

function Footer() {
    const [year, setYear] = useState(new Date().getFullYear());

    return (
        <footer>
            <div>
                <p>lukasz.frydrych@xsiadron.com</p>
                <p>
                    <a
                        onClick={() =>
                            (window.location.href = "/privacypolicy")
                        }
                    >
                        Privacy Policy
                    </a>
                </p>
            </div>
            <p>Copyright &copy; {year}. Created by Luke `xsiadron` Frydrych</p>
        </footer>
    );
}

export default Footer;
