import React, { useEffect, useRef, useState } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

import "./Socials.css";

import Social from "./components/Social";

import GithubIcon from "./assets/images/socials/Github.png";
import YoutubeIcon from "./assets/images/socials/Youtube.png";
import InstagramIcon from "./assets/images/socials/Instagram.png";
import TikTokIcon from "./assets/images/socials/Tiktok.png";
import DiscordIcon from "./assets/images/socials/Discord.png";
import SteamIcon from "./assets/images/socials/Steam.png";
import UsemeIcon from "./assets/images/socials/Useme.png";
import XIcon from "./assets/images/socials/X.png";
import PatreonIcon from "./assets/images/socials/patreon.png";

function Socials() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const [patreonText, setPatreonText] = useState("Support me :D");

    const mainControls = useAnimation();

    function updateGrid() {
        const gridCustomSizeChildCount = 1;
        const grid = document.querySelector("section#socials div#grid");
        const placeholderGrid = document.querySelector(".placeholder-grid");

        if (!grid || !placeholderGrid) return;

        if (placeholderGrid.childElementCount === 0) {
            const gridChildren = Array.from(grid.children);
            const baseChildren = gridChildren.slice(
                0,
                grid.childElementCount - gridCustomSizeChildCount
            );
            const specialChild =
                gridChildren[grid.childElementCount - 1].cloneNode(true);
            specialChild.className = "special-child";

            baseChildren.forEach((child) =>
                placeholderGrid.appendChild(child.cloneNode(true))
            );
            placeholderGrid.appendChild(specialChild);
        }

        adjustPatreonText();

        const lastChild = grid.lastElementChild;
        if (lastChild) {
            const { gridTemplateRows, gridTemplateColumns } =
                window.getComputedStyle(placeholderGrid);
            const rows = gridTemplateRows.split(" ").length;
            const columns = gridTemplateColumns.split(" ").length;

            const minColumns = columns === 1 ? 1 : 2;
            const maxColumns =
                rows === 1
                    ? 4
                    : grid.childElementCount - gridCustomSizeChildCount;
            const wynik = Math.min(
                maxColumns,
                Math.max(minColumns, columns * rows - 8)
            );

            lastChild.style.gridColumn = `span ${wynik}`;
        }
    }

    function adjustPatreonText() {
        const specialChild = document.querySelector(".special-child");

        if (specialChild) {
            const shouldSpanTwoColumns = window.innerWidth > 256;
            specialChild.style.gridColumn = shouldSpanTwoColumns
                ? "span 2"
                : "span 1";
        }

        const shrinkText = window.innerWidth <= 200;
        setPatreonText(shrinkText ? "" : "Patreon");
    }

    useEffect(() => {
        const handleResize = () => {
            updateGrid();
            adjustPatreonText();
        };

        window.addEventListener("resize", handleResize);

        updateGrid();
        adjustPatreonText();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView]);

    return (
        <section id="socials">
            <h1 className="brackets">Socials</h1>
            <motion.div
                ref={ref}
                variants={{
                    hidden: { opacity: 0, y: 50, zIndex: 0 },
                    visible: { opacity: 1, y: 0, zIndex: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.5, delay: 0 }}
            >
                <div className="placeholder-grid"></div>
                <div id="grid">
                    <Social
                        icon={GithubIcon}
                        text={"GitHub"}
                        link={"https://github.com/xsiadron"}
                        animateDelay={0}
                    />
                    <Social
                        icon={YoutubeIcon}
                        text={"YouTube"}
                        link={"https://www.youtube.com/@xsiadron"}
                        animateDelay={0.05}
                    />
                    <Social
                        icon={InstagramIcon}
                        text={"Instagram"}
                        link={"https://www.instagram.com/xsiadron/"}
                        animateDelay={0.1}
                    />
                    <Social
                        icon={TikTokIcon}
                        text={"TikTok"}
                        link={"https://www.tiktok.com/@xsiadron"}
                        animateDelay={0.15}
                    />
                    <Social
                        icon={XIcon}
                        text={"X"}
                        link={"https://twitter.com/xsiadron"}
                        animateDelay={0.2}
                    />
                    <Social
                        icon={DiscordIcon}
                        text={"Discord"}
                        link={""}
                        animateDelay={0.25}
                    />
                    <Social
                        icon={SteamIcon}
                        text={"Steam"}
                        link={"https://steamcommunity.com/id/xsiadron/"}
                        animateDelay={0.3}
                    />
                    <Social
                        icon={UsemeIcon}
                        text={"Useme"}
                        link={
                            "https://useme.com/pl/roles/contractor/xsiadron,329628/"
                        }
                        animateDelay={0.35}
                    />
                    <Social
                        additionalClasses={"patreon"}
                        icon={PatreonIcon}
                        text={patreonText}
                        link={
                            "https://patreon.com/user?u=33822994&utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink"
                        }
                        animateDelay={0.4}
                    />
                </div>
            </motion.div>
        </section>
    );
}

export default Socials;
