import React, { useRef, useEffect } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import "./PrivacyPolicy.css";
import PrivacyPolicyNavigation from "./PrivacyPolicyNavigation";
import Footer from "../../Footer";

function PrivacyPolicy() {
    const fadeInVariants = {
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
    };

    const MotionSection = ({ children }) => {
        const ref = useRef(null);
        const mainControls = useAnimation();
        const inView = useInView(ref);

        useEffect(() => {
            document.querySelector("footer p a").style.display = "none";
        })

        useEffect(() => {
            if (inView) {
                mainControls.start("visible");
            }
        }, [inView, mainControls]);

        return (
            <motion.div
                ref={ref}
                variants={fadeInVariants}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.4, delay: 0 }}
            >
                {children}
            </motion.div>
        );
    };

    return (
        <div className="app">
            <PrivacyPolicyNavigation />
            <div style={{ width: "100%", height: "50px" }}></div>
            <div className="privacy-policy" id="policy-section">
                <MotionSection>
                    <h2>Privacy Policy</h2>
                </MotionSection>

                <MotionSection>
                    <h3>I. General provisions</h3>
                    <p>
                        This privacy policy outlines how the personal data of
                        Users, necessary for providing services related to all
                        games, applications, websites, and other digital content
                        created by xsiadron (Łukasz Frydrych), are collected,
                        processed, and stored.
                    </p>
                    <p>
                        The service collects only personal data necessary for
                        the provision and development of the services offered.
                    </p>
                    <p>
                        Personal data collected through the service is processed
                        in accordance with the Regulation (EU) 2016/679 of the
                        European Parliament and of the Council of 27 April 2016
                        on the protection of natural persons with regard to the
                        processing of personal data.
                    </p>
                </MotionSection>

                <MotionSection>
                    <h3>II. Data controller</h3>
                    <p>
                        The data controller for personal data is xsiadron, and
                        for mobile apps available on Google Play, also Google
                        Play.
                    </p>
                </MotionSection>

                <MotionSection>
                    <h3>III. Purpose of collecting personal data</h3>
                    <ul>
                        <li>
                            Communication with the User (contact form, etc.).
                        </li>
                        <li>
                            Newsletter delivery (after the User's consent to
                            receive it).
                        </li>
                        <li>Provision of social services.</li>
                        <li>Promotion of the Administrator's offer.</li>
                        <li>Marketing, remarketing, affiliation.</li>
                        <li>Personalization of the Service for Users.</li>
                        <li>
                            Ensure optimal application performance and record
                            user actions.
                        </li>
                        <li>Analytical and statistical activities.</li>
                    </ul>
                    <p>
                        Providing data is voluntary but necessary to conclude a
                        contract and use functionalities of the Service.
                    </p>
                </MotionSection>

                <MotionSection>
                    <h3>IV. Sharing personal data</h3>
                    <p>
                        User personal data may be transferred depending on the
                        functionality to entities associated with the
                        Administrator, its subcontractors, entities cooperating
                        with the Administrator, e.g., companies handling
                        e-payments, courier/postal service companies, law firms.
                    </p>
                </MotionSection>

                <MotionSection>
                    <h3>V. User rights</h3>
                    <ul>
                        <li>Access their personal data.</li>
                        <li>
                            Rectify, delete, or restrict processing of their
                            data.
                        </li>
                        <li>Transfer their data.</li>
                        <li>Object to processing.</li>
                        <li>
                            Withdraw consent at any time (which does not affect
                            the lawfulness of processing based on consent before
                            its withdrawal).
                        </li>
                    </ul>
                    <p>
                        Users can submit a request regarding their rights to{" "}
                        <a href="mailto:lukasz.frydrych@xsiadron.com">
                            lukasz.frydrych@xsiadron.com
                        </a>
                        .
                    </p>
                </MotionSection>

                <MotionSection>
                    <h3>VI. Contact details</h3>
                    <p>
                        For any inquiries, please contact via email:{" "}
                        <a href="mailto:lukasz.frydrych@xsiadron.com">
                            lukasz.frydrych@xsiadron.com
                        </a>
                        .
                    </p>
                </MotionSection>

                <MotionSection>
                    <h3>VII. Final provisions</h3>
                    <p>
                        The Administrator has the right to make changes to the
                        Privacy Policy, with Users' rights not being limited.
                    </p>
                </MotionSection>
            </div>

            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
