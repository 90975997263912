import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import "../../Navigation.css";
import AvatarTranslucent from "../../assets/images/AvatarTranslucent.png";

function Navigation() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView]);

    return (
        <nav>
            <motion.div
                ref={ref}
                variants={{
                    hidden: { opacity: 0, y: -50 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.75, delay: 0.1 }}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <button onClick={() => (window.location = "/")}>
                    <li className="brackets" id="about-link">
                        <img src={AvatarTranslucent} height={75} />
                    </li>
                </button>
                <ul>
                    <button onClick={() => (window.location = "/")}>
                        <li className="brackets" id="about-link">
                            Home Page
                        </li>
                    </button>
                    <button onClick={() => (window.location = "/")}>
                        <li className="brackets hover" id="about-link">
                            Privacy Policy
                        </li>
                    </button>
                </ul>
            </motion.div>
        </nav>
    );
}

export default Navigation;
