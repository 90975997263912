import React, { useEffect } from "react";

import "./Projects.css";
import Box from "./components/Box";

// Images
import LibrusStatisticsImage from "./assets/images/LibrusStatistics/LibrusStatistics.png";
import LibrusStatisticsBackgroundImage from "./assets/images/LibrusStatistics/LibrusStatisticsBackground.png";

import VeamlyImage from "./assets/images/Veamly/Veamly.png";
import VeamlyBackgroundImage from "./assets/images/Veamly/VeamlyBackground.png";

import YoutubeMusicVolumeSliderImage from "./assets/images/YoutubeMusicVolumeSlider/YoutubeMusicVolumeSlider.png";
import YoutubeMusicVolumeSliderBackgroundImage from "./assets/images/YoutubeMusicVolumeSlider/YoutubeMusicVolumeSliderBackground.png";

import SharkBreakImage from "./assets/images/SharkBreak/SharkBreak.png";
import SharkBreakBackgroundImage from "./assets/images/SharkBreak/SharkBreakBackground.png";

import FilareImage from "./assets/images/Filare/Filare.png";
import FilareBackgroundImage from "./assets/images/Filare/FilareBackground.png";

import HeartOfTheSettlementImage from "./assets/images/HeartOfTheSettlement/HeartOfTheSettlement.png";
import HeartOfTheSettlementBackgroundImage from "./assets/images/HeartOfTheSettlement/HeartOfTheSettlementBackground.png";

function Projects() {
    useEffect(() => {}, []);

    return (
        <section id="projects">
            <h1 className="brackets">Projects</h1>
            <div>
                <div id="projects-grid">
                    <Box
                        title={"Librus Statistics [Polish Language]"}
                        description={
                            "Is a web application developed using React that allows you to access information from portal.librus.pl/rodzina about your own account in terms of subjects, attendance and grades of the current year."
                        }
                        link={"https://ls.xsiadron.com"}
                        image={LibrusStatisticsImage}
                        backgroundImage={LibrusStatisticsBackgroundImage}
                        author={"xsiadron"}
                        date={"Released in 06.2023"}
                    />

                    <Box
                        title={"Veamly - Assign your meanings"}
                        description={
                            "An application to record your own meanings of words or their translations. It facilitates memorization and learning and can be used as a kind of notebook. You will find such functionalities as adding your own glossaries or automatic tests and fiches just for them."
                        }
                        link={
                            "https://play.google.com/store/apps/details?id=com.xsiadron.veamly"
                        }
                        image={VeamlyImage}
                        backgroundImage={VeamlyBackgroundImage}
                        author={"xsiadron"}
                        date={"Released in 02.2023"}
                    />

                    <Box
                        title={"Youtube Music Volume Slider"}
                        description={
                            "Browser extension that streamlines volume control on YouTube Music by expanding the slider and showing the current volume level in percentages."
                        }
                        link={
                            "https://chromewebstore.google.com/detail/youtube-music-volume-slid/igbflikmkacfojkpipddglnmaaoanlaj"
                        }
                        image={YoutubeMusicVolumeSliderImage}
                        backgroundImage={YoutubeMusicVolumeSliderBackgroundImage}
                        author={"xsiadron"}
                        date={"Released in 07.2024"}
                    />
                </div>
            </div>
        </section>
    );
}

export default Projects;
