import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import "./Navigation.css";
import { ScrollTo } from "./scripts/Scroll";

function Navigation() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        }
    }, [isInView]);

    return (
        <nav>
            <motion.div
                ref={ref}
                variants={{
                    hidden: { opacity: 0, y: -50 },
                    visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.75, delay: 0.1 }}
            >
                <ul>
                    <button onClick={() => ScrollTo("about")}>
                        <li className="brackets hover" id="about-link">
                            About
                        </li>
                    </button>
                    <button onClick={() => ScrollTo("projects")}>
                        <li className="brackets" id="projects-link">
                            Projects
                        </li>
                    </button>
                    <button onClick={() => ScrollTo("socials")}>
                        <li className="brackets" id="socials-link">
                            Socials
                        </li>
                    </button>
                </ul>
            </motion.div>
        </nav>
    );
}

export default Navigation;
